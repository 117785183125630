.skills {
  position: relative;
  padding: 100px 0;
}
.skills::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  background-image: url("../../assets/skillsBG.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media (max-width: 767px) {
  .skills .tech--list {
    flex-direction: column;
    gap: 40px;
  }
}
.skills .tech--list .box {
  position: relative;
  background-color: var(--Brand2);
  color: var(--bg1);
  text-align: center;
  padding: 20px;
  border-radius: 0 10px 10px 0;
  z-index: 100;
  width: 300px;
}
.skills .tech--list .box::after {
  content: "";
  position: absolute;
  height: 100%;
  background: var(--css);
  width: 12px;
  top: 0;
  left: -10px;
  z-index: -1;
  border-radius: 10px 0px 0px 10px;
}
.skills .tech--list .box svg {
  margin: 0 auto;
  font-size: 22px;
}
.skills .skills--detail {
  flex-wrap: wrap;
}
.skills .skills--detail {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;
  text-align: center;
  width: 80%;
  margin: 0 auto;
  padding: 40px 0;
}
.skills .skills--detail div {
  width: fit-content;
  margin: 0 auto;
  padding: 20px 0;
}
.skills .skills--detail h2 {
  margin-top: 20px;
}
.skills .skills--detail svg {
  font-size: 50px;
  background-color: transparent !important;
}
.skills .skills--detail .html {
  color: var(--html);
}
.skills .skills--detail .icon {
  color: var(--white);
  padding: 30px;
  border-radius: 50%;
}
.skills .skills--detail .html {
  color: var(--html);
}
.skills .skills--detail .css {
  color: var(--css);
}

.skills .skills--detail .js {
  color: var(--js);
}
.skills .skills--detail .react {
  color: var(--react);
}
.skills .skills--detail .html .icon {
  background-color: var(--html);
}
.skills .skills--detail .css .icon {
  background-color: var(--css);
}

.skills .skills--detail .js .icon {
  background-color: var(--js);
}
.skills .skills--detail .react .icon {
  background-color: var(--react);
}
.skills .skills--detail .nodeJs .icon {
  background-color: var(--nodejs);
}
.skills .skills--detail .express .icon {
  background-color: var(--expressJs);
}
.skills .skills--detail .mongodb .icon {
  background-color: var(--mongodb);
}
