.works {
  padding: 100px 0;
  background-color: var(--bg2);
  position: relative;
  z-index: 1;
}
.works::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/Works.png");
  z-index: -1;
  mix-blend-mode: multiply;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.works .slides {
  max-width: 800px;
  width: 800px;
  margin: 0 auto;
}
@media (max-width: 810px) {
  .works .slides {
    width: 600px;
  }
  .works .slides img {
    width: 300px;
  }
}
@media (max-width: 767px) {
  .works .slides {
    width: 300px;
  }
  .works .slides img {
    width: 300px;
  }
}
.works .slides .swiper-wrapper {
  text-align: center;
}
.works .slides img {
  margin: auto;
  user-select: none;
}
.works .swiper-button-next,
.works .swiper-button-prev {
  height: 72px;
  width: 72px;
  color: #fff;
  border-radius: 50%;
  background-color: var(--bg1);
}
@media (max-width: 767px) {
  .works .swiper-button-next,
  .works .swiper-button-prev {
    opacity: 0.6;
  }
}
