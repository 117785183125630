@media (max-width: 1399px) {
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.666667%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.333333%;
  }
}
@media (max-width: 767px) {
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}
