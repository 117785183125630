@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@100;200;300;400;500;600;700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
:root {
  --bg1: #292f36;
  --bg2: #1f1e23;
  --Brand1: #12f7d6;
  --Brand2: #98f1ec;
  --grey: #34454d;
  --white: #fff;
  --html: #e54e26;
  --css: #0c79b8;
  --js: #e7a020;
  --react: #28a6ed;
  --nodejs: #529f41;
  --expressJs: #393939;
  --mongodb: #001e2b;
}
#root {
  overflow: hidden;
}
.text-primary {
  color: var(--brand-1) !important;
}
.bg-primary {
  background-color: var(--brand-1) !important;
}
.border-primary {
  border-color: var(--brand-1) !important;
}
.text-dark {
  color: var(--bg1) !important;
}
.fit-content {
  width: fit-content;
}
ul {
  margin: 0 !important;
}
@media (max-width: 999px) {
  .w-sm-100 {
    width: 100% !important;
  }
  .ml-sm-2 {
    margin-left: 8px;
  }
}
:root {
  --article-u-font-family: "Ubuntu-Light", Helvetica;
  --article-u-font-size: 16px;
  --article-u-font-style: normal;
  --article-u-font-weight: 300;
  --article-u-letter-spacing: 0px;
  --article-u-line-height: 32px;
  --bg-1: rgba(41, 47, 54, 1);
  --bg-2: rgba(26, 30, 35, 1);
  --BG-text-u-font-family: "Ubuntu-Regular", Helvetica;
  --BG-text-u-font-size: 117px;
  --BG-text-u-font-style: normal;
  --BG-text-u-font-weight: 400;
  --BG-text-u-letter-spacing: 0px;
  --BG-text-u-line-height: 134px;
  --brand-1: rgba(18, 247, 214, 1);
  --brand-2: rgba(152, 250, 236, 1);
  --button-u-font-family: "Ubuntu-Regular", Helvetica;
  --button-u-font-size: 20px;
  --button-u-font-style: normal;
  --button-u-font-weight: 400;
  --button-u-letter-spacing: 0px;
  --button-u-line-height: 24px;
  --code-m-font-family: "IBMPlex Mono-Regular", Helvetica;
  --code-m-font-size: 14px;
  --code-m-font-style: normal;
  --code-m-font-weight: 400;
  --code-m-letter-spacing: 0px;
  --code-m-line-height: 18px;
  --css: rgba(12, 115, 184, 1);
  --grey: rgba(67, 69, 77, 1);
  --h1-u-font-family: "Ubuntu-Regular", Helvetica;
  --h1-u-font-size: 64px;
  --h1-u-font-style: normal;
  --h1-u-font-weight: 400;
  --h1-u-letter-spacing: 0px;
  --h1-u-line-height: 72px;
  --h2-m-font-family: "IBMPlex Mono-Medium", Helvetica;
  --h2-m-font-size: 32px;
  --h2-m-font-style: normal;
  --h2-m-font-weight: 500;
  --h2-m-letter-spacing: 0px;
  --h2-m-line-height: 42px;
  --h2-u-font-family: "Ubuntu-Regular", Helvetica;
  --h2-u-font-size: 32px;
  --h2-u-font-style: normal;
  --h2-u-font-weight: 400;
  --h2-u-letter-spacing: 0px;
  --h2-u-line-height: 36px;
  --html: rgba(229, 79, 38, 1);
  --js: rgba(231, 160, 32, 1);
  --label-u-l-font-family: "Ubuntu-Light", Helvetica;
  --label-u-l-font-size: 14px;
  --label-u-l-font-style: normal;
  --label-u-l-font-weight: 300;
  --label-u-l-letter-spacing: 0px;
  --label-u-l-line-height: 16px;
  --label-u-m-font-family: "Ubuntu-Medium", Helvetica;
  --label-u-m-font-size: 14px;
  --label-u-m-font-style: normal;
  --label-u-m-font-weight: 500;
  --label-u-m-letter-spacing: 0px;
  --label-u-m-line-height: 16px;
  --logo-m-font-family: "IBMPlex Mono-Medium", Helvetica;
  --logo-m-font-size: 32px;
  --logo-m-font-style: normal;
  --logo-m-font-weight: 500;
  --logo-m-letter-spacing: 0px;
  --logo-m-line-height: 42px;
  --media-m-font-family: "IBMPlex Mono-Regular", Helvetica;
  --media-m-font-size: 16px;
  --media-m-font-style: normal;
  --media-m-font-weight: 400;
  --media-m-letter-spacing: 0px;
  --media-m-line-height: 20px;
  --menu-m-font-family: "IBMPlex Mono-Regular", Helvetica;
  --menu-m-font-size: 24px;
  --menu-m-font-style: normal;
  --menu-m-font-weight: 400;
  --menu-m-letter-spacing: 0px;
  --menu-m-line-height: 32px;
  --number-m-font-family: "IBMPlex Mono-Medium", Helvetica;
  --number-m-font-size: 48px;
  --number-m-font-style: normal;
  --number-m-font-weight: 500;
  --number-m-letter-spacing: 0px;
  --number-m-line-height: 62px;
  --para-m-font-family: "IBMPlex Mono-Regular", Helvetica;
  --para-m-font-size: 16px;
  --para-m-font-style: normal;
  --para-m-font-weight: 400;
  --para-m-letter-spacing: 0px;
  --para-m-line-height: 20px;
  --para-u-font-family: "Ubuntu-Light", Helvetica;
  --para-u-font-size: 16px;
  --para-u-font-style: normal;
  --para-u-font-weight: 300;
  --para-u-letter-spacing: 0px;
  --para-u-line-height: 18px;
  --react: rgba(40, 169, 224, 1);
  --variable-collection-black: rgba(0, 0, 0, 1);
  --variable-collection-white: rgba(255, 255, 255, 1);
  --white: rgba(255, 255, 255, 1);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
::selection {
  color: #888;
  background-color: var(--brand-2);
}
body {
  font-family: "Ubuntu", sans-serif;
  margin: 0;
  background-color: var(--bg1);
  color: var(--white);
  /* cursor: url("./assets/cursor-main.png"), auto; */
  /* overflow: hidden; */
  /* height: 100%; */
}
/* button {
  cursor: url("./assets/cursor-stop.png"), auto !important;
} */
a {
  text-decoration: none;
  color: var(--white);
  /* cursor: url("./assets/cursor-stop.png"), auto !important; */
}
/* .swiper-button-prev,
.swiper-button-next {
  cursor: url("./assets/cursor-stop.png"), auto !important;
} */
ul {
  list-style: none;
  padding: 0;
}
h1 {
  font-size: 1.5rem;
  color: var(--white);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: capitalize;
  margin: 0 !important;
}
h1 {
  font-size: 64px;
  font-weight: normal;
}
h2 {
  font-size: 32px;
  line-height: 36px;
  font-weight: normal;
}
button {
  font-size: 20px;
  line-height: 24px;
}
article {
  font-size: 16px;
  line-height: 32px;
}
.BG-text-U {
  font-weight: normal;
  font-size: 117px;
  font-family: "Ubuntu";
}
.H1-U {
  font-weight: normal;
  font-size: 64px;
}
.H2-U {
  font-weight: normal;
  font-size: 32px;
  font-family: "Ubuntu";
}
.Button-U {
  font-weight: normal;
  font-size: 20px;
  font-family: "Ubuntu";
}
.Article-U {
  font-weight: lighter;
  font-size: 16px;
  font-family: "Ubuntu";
}
.Para-U {
  font-weight: lighter;
  font-size: 16px;
  font-family: "Ubuntu";
}
.Label-U-M {
  font-size: 14px;
  font-weight: medium;
  font-family: "Ubuntu";
}
.Label-U-L {
  font-weight: lighter;
  font-size: 14px;
  font-family: "Ubuntu";
}
.Number-M {
  font-family: "IBM Plex Mono";
  font-weight: medium;
  font-size: 48px;
}
.H2-M {
  font-family: "IBM Plex Mono";
  font-weight: medium;
  font-size: 32px;
}
.Logo-M {
  font-family: "IBM Plex Mono";
  font-weight: medium;
  font-size: 32px;
}
.Menu-M {
  font-family: "IBM Plex Mono";
  font-weight: medium;
  font-size: 24px;
}
.Media-M {
  font-family: "IBM Plex Mono";
  font-weight: normal;
  font-size: 16px;
}
.Para-M {
  font-family: "IBM Plex Mono";
  font-weight: normal;
  font-size: 16px;
}
.Code-M {
  font-family: "IBM Plex Mono";
  font-weight: medium;
  font-size: 14px;
}
.Logo-M {
  font-family: "IBM Plex Mono";
  font-size: 32px;
}
.container {
  width: 90%;
  margin: 0 auto;
}
.d-flexend {
  display: flex;
  align-items: flex-end;
}
i {
  position: absolute;
  height: 4px;
  width: 4px;
  background-color: #12f7d6;
  pointer-events: none;
  animation: spark 2s linear forwards;
}
@keyframes spark {
  0% {
    opacity: 0%;
    transform: translate(0, 0);
  }
  100% {
    opacity: 100%;
    transform: translate(var(--x), var(--y));
  }
}
.tags {
  color: var(--Brand1);
  font-size: 16px !important;
}
/* Start Componants */
.flex-align {
  display: flex;
  align-items: center;
}
.space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.space-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.header--shadow {
  padding: 5px 20px;
  background-color: rgb(255 255 255 / 50%);
  border-radius: 34px;
  width: fit-content;
  color: var(--dark-color);
}
@media screen and (max-width: 767px) {
  .mobile-hide {
    display: none;
  }
}
@media screen and (min-width: 767px) {
  .desktop-hide {
    display: none;
  }
}
@media (max-width: 1040px) {
  .tab-hide {
    display: none;
  }
}
@media (min-width: 1041px) {
  .tob-show {
    display: none;
  }
}
.button {
  color: var(--bg-1);
  padding: 14px;
  border-radius: 28px;
}
.main--title {
  margin: 0 auto;
  width: fit-content;
  text-align: left;
  padding: 70px 0;
}
.main--title h1 {
  position: relative;
  color: var(--Brand1);
  border-bottom: 4px solid var(--Brand1);
  width: fit-content;
  margin: 0 auto;
  padding: 10px 0;
}

.main--title h1::before,
.main--title h1::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  bottom: -10px;
  background-color: var(--Brand1);
}
.main--title p {
  letter-spacing: 0px;
  margin: 10px 0;
  /* width: 60%; */
}

/* start contact */

.contact {
  background-color: #1a1e23;
}
.contact .form .form-button {
  color: #fff;
  background-color: var(--Brand1);
  border-radius: 50px;
}
.contact .form input {
  outline: 0;
  background: 0;
  border-bottom: 1px solid var(--Brand2);
  display: block;
  width: 100%;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  color: #fff;
  padding: 0 6px;
}
.contact button {
  color: var(--Brand1);
  width: fit-content;
  border: 3px solid var(--Brand1);
  padding: 15px 30px;
  border-radius: 30px 0 30px 0;
  background: 0;
}
.contact .form input:focus {
  outline: 0;
}
.contact .form label {
  color: var(--Brand1);
}
/* end contact */
footer {
  width: 90%;
  margin: auto;
  color: #d6d6d6;
  padding: 10px 0;
}
footer a {
  color: var(--react);
}
footer svg {
  background-color: var(--Brand2);
  color: #000;
  padding: 6px;
  font-size: 30px;
  border-radius: 50%;
  margin-right: 12px;
}
/* start nav bar  */
nav {
  border-bottom: 1px solid #43454d;
}
nav .logo,
nav .icon {
  color: var(--Brand1);
}
nav .icon {
  height: 18px;
}
nav .logo {
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 42px; /* 131.25% */
}
nav .brand h2 {
  font-family: "IBM Plex Mono";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 42px; /* 131.25% */
  text-transform: capitalize;
}
nav ul {
  width: 50%;
}
nav .social--media {
  gap: 32px;
}
nav .social--media li {
  gap: 8px;
  display: flex;
  align-items: center;
}
nav li a:hover {
  color: var(--Brand2);
  transition: 0.3s;
}
@media (max-width: 767px) {
  nav .brand h2 {
    font-size: 20px;
  }
  nav li a {
    font-size: 20px;
  }
  nav .social--media li {
    font-size: 14px;
  }
}

/* start hero  */
.hero {
  margin: auto;
  min-height: calc(100vh - 91px);
  background-color: var(--bg1);
}
.hero .title {
  color: var(--brand-2);
  font-family: var(--BG-text-u-font-family);
  font-size: var(--BG-text-u-font-size);
  font-style: var(--BG-text-u-font-style);
  font-weight: var(--BG-text-u-font-weight);
  width: auto;
  text-align: center;
  padding-top: 30px;
}
/* .hero .holder {
  display: grid;
  grid-template-areas: "card info info  stats ";
  margin: 20px 0px;
  align-items: center;
  gap: 50px;
} */
@media (max-width: 1200px) {
  .hero {
    padding: 30px 0;
  }
  /* .hero .holder {
    grid-template-areas:
      "card card"
      "info stats";
    gap: 0;
  } */
  .hero .holder .card {
    margin: 0 auto;
    width: 60%;
  }
}
@media (max-width: 767px) {
  .hero h1 {
    display: none;
  }
  /* .hero .holder {
    grid-template-areas:
      "card "
      "info"
      "stats";
    gap: 0;
  } */
}
/* Start Card */
.hero .holder .card {
  border: 2px solid var(--white);
  border-radius: 150px 0;
  background-color: var(--bg1);
  padding: 28px;
  position: relative;
  width: 378px;
  max-width: 100%;
}
.hero .holder .card::after {
  content: "";
  position: absolute;
  background: var(--Brand1);
  height: 100%;
  width: 100%;
  top: -7px;
  left: -7px;
  border-radius: 150px 0;
  z-index: -1;
}
.hero .holder .card img {
  width: 96px;
  margin: 0 auto;
  border-radius: 50%;
}
.hero .holder .card .personal--info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 30px;
}
.hero .holder .card .info {
  line-height: 2rem;
}
.hero .holder .card div {
  font-family: "IBM Plex Mono";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #fff;
}
.hero .holder .card div > svg {
  color: var(--brand-2);
  margin-right: 10px;
  font-size: 16px;
}
.hero .holder .card .skills {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 14px 0;
  /* margin: 15px 0; */
}
.hero .holder .card .skills::before {
  background: 0;
}
.hero .holder .card .skills > div {
  padding: 2px;
  background-color: var(--brand-1);
  border-radius: 8px;
  color: var(--BG-1, #292f36);
  font-family: "IBM Plex Mono";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-right: 10px;
}
.hero .CV {
  background-color: var(--white);
  width: 80%;
  /* margin: auto; */
  text-align: center;
}
.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200%;
  z-index: -1;
  overflow: hidden;
  mix-blend-mode: screen;
  opacity: 0.7;
}
@media (min-width: 1199px) {
  .video-background {
    height: 130%;
  }
}
@media (min-width: 767px) {
  .video-background {
    height: 155%;
  }
}
@media (max-width: 767px) {
  .video-background {
    height: 200%;
  }
}

#myVideo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content-container {
  position: relative;
  z-index: 1;
  width: 100%;
}

/* End Card */
/* Start info */
.hero .holder .info {
  margin: 20px;
}
.hero .holder .info .content {
  line-height: 1;
}
.hero .holder .info .content div {
  font-family: Ubuntu;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  text-transform: capitalize;
}
.hero .holder .info .content div span {
  color: var(--Brand1);
}
.hero .holder .info .content p {
  color: var(--White, var(--White, #fff));
  /* Para-M */
  font-family: "IBM Plex Mono";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  max-width: 100%;
}
.hero .holder .info .content .lets-talk {
  color: var(--Brand-1, #12f7d6);
  /* H2-M */
  font-family: "IBM Plex Mono";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  text-transform: capitalize;
  margin-top: 20px;
}
.hero .holder .info .content .lets-talk svg {
  margin-left: 30px;
}
/* End info */

/* Start stats */
.hero .holder .stats {
  padding: 22px;
  background-color: var(--bg-2);
  width: 100%;
  border-radius: 60px;
}
.hero .holder .stats .stat {
  color: var(--Brand-1, #12f7d6);
  /* Number-M */
  font-family: "IBM Plex Mono";
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  text-transform: capitalize;
  padding: 14px 0;
}
.hero .holder .stats .stat div {
  color: var(--White, var(--White, #fff));
  font-family: "IBM Plex Mono";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-left: 16px;
}
@media (max-width: 1399px) {
  .hero .holder .stats .stat div {
    font-size: 22px;
  }
}
@media (max-width: 767px) {
  .hero .holder .stats .stat div {
    font-size: 26px;
  }
}
/* End stats */
.side-panel {
  position: fixed;
  top: 100px;
  height: fit-content;
  width: fit-content;
  background-color: var(--bg-2);
  padding: 6px 10px;
  z-index: 200;
  left: 100px;
  border-radius: 50px;
  border: 2px solid #fff;
}
.side-panel div {
  color: #f8f9fa;
  font-size: 32px;
  margin-bottom: 10px;
  border-radius: 50%;
  /* padding: 4px; */
}

.side-panel div a {
  padding: 10px;
}
.side-panel div a:hover {
  color: var(--bg2);
  background-color: #fff;
  border-radius: 50%;
}
/* .side-panel div:hover {
  background-color: #fff;
} */
.side-panel div:last-child {
  margin-bottom: 0;
}
@media (max-width: 999px) {
  .side-panel {
    display: none;
  }
}
