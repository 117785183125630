.about {
  background-color: #1c2024;
  position: relative;
  z-index: 1;
}

.about::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  background-image: url("../../assets/About-meBG.png");
  z-index: -1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.about .container {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 100px 0;
}
@media (max-width: 1200px) {
  .about .container {
    flex-direction: column;
    gap: 40px;
  }
  .about .card {
    width: 100% !important;
  }
}
.about .card {
  color: #fff;
  width: 64%;
  padding: 20px;
  /* background-color: #1c2024; */
  border: 0;
  background: 0;
}
.about .card h1 {
  padding: 7px 20px;
  border-radius: 60px 0 60px 0;
  border: 4px solid var(--Brand1);
  width: fit-content;
  background-color: var(--bg1);
  margin: 34px 0;
}
@media (max-width: 767px) {
  .about .card h1 {
    margin: 34px auto;
  }
}
.about .card p {
  padding: 24px;
  background-color: var(--bg1);
  border-radius: 40px;
}
.about .card p div {
  color: var(--Brand1);
  font-size: 36px;
  margin-bottom: 10px;
}
.about .card p span {
  color: var(--Brand1);
}
.about .image {
  width: fit-content;
}
.about .image img {
  max-width: 100%;
}
@media (max-width: 767px) {
  .about .card h1 {
    font-size: 40px;
  }
}
